<template>
    <div id="app">
        <suspension @closeMp3="closeMp3" v-if="showSuspension"/>
        <router-view v-if="routerAlive" />
        <!-- <fireTest></fireTest> -->
        <div class="AppDialog">
            <el-dialog :title="dialogtitle" :visible.sync="dialogVisible" v-if="dialogVisible" :append-to-body='true'
                :modal-append-to-body='false' width="1150px" custom-class="center-dialog">
                <div class="AppDialogBox" style="display: flex;justify-content: center;">
                    <planPicture v-if="dialogTabShow == 'plan'" :AxiosURL="getURL(AxiosURL)" :key="planShow"
                        ref="planPicture" :position="IMGposition"></planPicture>
                    <videoCon v-if="false" :url="videoUrl" style="width:1000px;height: 235px;"></videoCon>
                    <alarmDetail v-if="dialogTabShow == 'alarmNews'" ref="alarmDetail" :alarm_data="alarm_data"
                        :mapPosition="mapPosition" :allAlarmData="allAlarmData" @clearAlarm="clearAlarm"></alarmDetail>
                    <videoList v-if="dialogTabShow == 'video'" :alarmNews="alarmData"></videoList>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import planPicture from "@/views/NewestHomePage/AlarmPlatform/list/planPicture.vue"
import videoCon from "@/components/Public/video.vue"
import alarmDetail from "@/components/Public/App/alarmDetails.vue"
import videoList from "@/components/Public/videoList/hwVideo.vue"
import messageAll from "./public_Js/messageAll"
import suspension from "@/components/Public/suspension.vue"
// import mqtt from 'mqtt'
export default {
    components: {
        // fireTest
        planPicture,
        videoCon,
        alarmDetail,
        videoList,
        suspension
    },
    provide() {
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            connection: {
                host: 'mqtt.tranthing.com',
                // host: '192.168.3.176',
                port: 9001,
                path: '/'
            },
            userName: "",
            mtopic: "device/alarm/",
            alarmMsg: "",
            dialogVisible: false,
            // 平面图url
            AxiosURL: '',
            IMGposition: '',
            planShow: 0,
            videoUrl: '',
            dialogTabShow: '',
            alarmData: {},
            mapPosition: {},

            dialogtitle: '',
            notifications: {},

            newHight: '',
            newVideoWidth: '',
            timeDeatail: null,
            videoMsg: null,
            videoUrlBlob: null,
            audioLoop: null,
            alarmId: null,
            // 无感刷新
            routerAlive: true,
            // mqtt是否首次重连
            isFirstReconnect: true,
            isFirstError: true,
            blackAlarmDevice: [], // 报警黑名单
            // mqtt断开测试
            mqttLossTime: 0,
            isOpenTips: false,
            worker: null, 
            mqttText: null,
            showSuspension: true,
            mqttData: null,
            policeState: false,
        }
    },
    watch: {
        getAlarmStatusByStore(nv){
            if (nv === false) {
                if (this.audioLoop) clearTimeout(this.audioLoop)
                window.speechSynthesis.cancel();
                this.videoMsg = null;
                this.audioLoop = null;
                this.mqttData = null;
                this.policeState = false;
            }
        },
        $route(r) {
            if (r.path === "/login" ) {
                this.showSuspension = false;
                this.$notify.closeAll();
                window.speechSynthesis.cancel();
                this.mqttData = null;
                if (this.audioLoop) clearTimeout(this.audioLoop)
                if( !this.$store.state.postMessage ) return;
                this.$store.state.postMessage({
                    cancelSubScript: true,
                })
            } else {
                this.showSuspension = true;
            }
        },
        mqttData() {
            // if (this.videoMsg || this.audioLoop) return
            if(!this.mqttData) return;
            if (this.audioLoop) clearTimeout(this.audioLoop)
            window.speechSynthesis.cancel();
            this.videoMsg = null;
            this.audioLoop = null;

            this.policeState = true;
            let alarmType = localStorage.getItem("aduio");
            if (alarmType == 'addr') {
                if (this.audioLoop) clearTimeout(this.audioLoop)
                this.playVoice(this.mqttText)
            } else if (alarmType === "mp3" ) {
                window.speechSynthesis.cancel();
                this.getVideoUrl()
            }
        },
    },
    mounted() {
        let loginHref = window.location.href;
        if (loginHref.search("token=") != -1 || (loginHref.search("/login") != -1)) {
            this.showSuspension = false;
            if ( this.worker.postMessage) {
                this.worker.postMessage({
                    cancelSubScript: true,
                })
            }
            if (loginHref.search("/token=") != -1) {
                let token = loginHref.substring(loginHref.indexOf("token=") + "token=".length);
                if (!token) return;
                this.$store.commit("setToken", token);
                this.getUserInfo()
            }
        } else {
            if(this.worker.postMessage) {
                this.worker.postMessage({
                    userName: JSON.parse(localStorage.getItem("AllTitle")).login_name
                })
            }
        }
        localStorage.getItem('aduio') ? '' : localStorage.setItem('aduio', 'mp3')
        // talbe重新赋值高度
        this.newHeight()
        this.newWidth = window.devicePixelRatio * 1064 + 'px'
        this.newVideoWidth = window.devicePixelRatio * 600 + 'px'
        window.addEventListener("resize", this.getResize)

        window.document.documentElement.setAttribute("data-theme", this.$CONFIG.THEME);
        if (localStorage.getItem("token")) {
            // this.getALltitle()
        } 

        window.addEventListener("beforeunload", e => {
            this.unloadFn(e);
        });
    }, 
    // 销毁函数
    beforeDestroy() {
        // 告诉worker销毁mqtt
        
        window.removeEventListener("resize", this.getResize);
        window.removeEventListener("beforeunload", e => {
            this.unloadFn(e);
        });
        if (!this.$store.state.postMessage) return;
        this.$store.state.postMessage({
            cancelSubScript: true,
        })
    },
    async created() {  
        if (!window.Worker) { 
            throw new Error('当前浏览器不支持多线程')
        }
        
        this.worker = new Worker("../worker/web.worker.js"); 
        // this.worker.postMessage({
        //     userName: localStorage.getItem("loginName")
        // })
        // 监听子线程信息
        this.worker.addEventListener('message', this.messageHandle)

        this.$store.commit("setWorker", this.worker);
    },
    destroyed() {
        
        // 记得销毁线程
        this.worker.removeEventListener('message', this.messageHandle)
        this.worker.terminate();
    },
    methods: {
        async getUserInfo() {
            let title = await this.$API.account_basic.loginNews.get({
                language: localStorage.getItem('theLanguage')
            })
            console.log( "title", title );
            let loginName = title.data.login_name;
            this.$store.state.worker.postMessage({
                userName: loginName
            })
            this.$store.commit("setLoginName", loginName);
            this.$store.commit("setUserRoleId", title.data.role_id);
            // 先把 路由 存储好后续跳转会用到
            this.$store.commit('setMenu', title.data.menu);
            this.$store.state.menu = title.data.menu;
            if (title.data.platform_title == '') {
                title.data.platform_title = this.$t("basic.newLogin.platformTitle") //"安防后台管理"
            }
            if (title.data.entity_name == '') {
                title.data.entity_name = this.$t("basic.newLogin.title") //"智慧安防云平台"
            }
            this.$store.commit('getAllTitle', title.data)
        },
        unloadFn() {
            if (this.$route.path === "/login") return;
            let token = localStorage.getItem("token");
            if (!token) {
                this.$router.push({ name: "login" })
                if (!this.$store.state.postMessage) return;
                this.worker.postMessage({
                    cancelSubScript: true,
                })
            }
        },
        closeMp3(val) {
            if (!this.policeState) return;
            if (val === "closeMp3") {
                if (this.audioLoop) clearInterval(this.audioLoop);
                if (this.videoMsg) window.speechSynthesis.cancel()
                this.videoMsg = null
                this.audioLoop = null;
            } else if (val === "addr") {
                if (this.audioLoop) clearInterval(this.audioLoop);
                this.playVoice(this.mqttText)
            } else if( val === "mp3" ) {
                if (this.videoMsg) window.speechSynthesis.cancel()
                this.getVideoUrl();
            }
        },
        // 消息处理
        messageHandle(e) {
            console.log('Worker主线程收到子线程信息: ', e.data);
            if( !e.data.mqttInfo ) return
            let json_obj = e.data.mqttInfo;
            this.mqttData = json_obj;
            if (this.$route.path == "/login" || this.$route.path == "/AlarmPlatform" || this.$route.path == "/FiremAlarmSystem" || this.$route.path == "/GasSafetySystem") {
                console.log("app.vue收到mqtt")
                return
            }
            if (this.$route.path == "/multiScreenMonitoring") {
                if (json_obj.device_type.search('摄像头') != -1) {
                    return;
                }
            }
            // let myTopic = this.mtopic + this.userName
            this.showNotify(json_obj)
        },
        openMqttCloseTips() {
            if (!this.isOpenTips) {
                this.isOpenTips = true
            } else {
                return
            }
            this.$alert('平台和服务器连接断开，点击确定刷新页面重连', '平台连接异常', {
                confirmButtonText: '确定',
                callback: action => {
                    this.isOpenTips = false
                    if (action == "confirm") {
                        if (this.mqttLossTime == 0) {
                            messageAll.successOpen("已经重连成功，不刷新页面")
                        } else {
                            location.reload()
                        }
                    } else {
                        if (this.mqttLossTime == 0) {
                            messageAll.successOpen("已经重连成功，不刷新页面")
                        } else {
                            location.reload()
                        }
                    }
                }
            });
        },
        // 无感刷新
        async reload() {
            await this.getALltitle()
            this.routerAlive = false
            this.$nextTick(() => {
                this.routerAlive = true
            })
        },
        getVideoUrl() {
            let audio = new Audio()
            audio.src = 'https://web.tranthing.com/api/static/work-file/alarm.mp3'
            audio.play()
            this.audioLoop = setInterval(() => {
                audio.play()
            }, 1000)
        },

        async playVoice(text) {
            this.videoMsg = new SpeechSynthesisUtterance();
            let language = localStorage.getItem('theLanguage')
            if (language == "zh") {
                this.$store.commit('changeLanguage', language)
                this.videoMsg.lang = "zh-CN";
                this.videoMsg.text = "位于" + text + '的设备正在报警,请及时确认！'
            }
            if (language == "en") {
                this.videoMsg.lang = "en-US";
                this.videoMsg.text = 'The' + text + ' address is currently under alarm. Please check it promptly'
            }
            this.videoMsg.onend = () => {
                this.playVoice(text)
            }
            window.speechSynthesis.speak(this.videoMsg);
        },
        getResize() {
            this.newHeight()
            this.changeDetail()
            this.changeTime()
        },
        newHeight() {
            let bodyHight = window.innerHeight
            let height = bodyHight - 303 + 'px'
            let noExHeight = bodyHight - 268 + 'px'
            this.$store.commit('changeNoExTableHeight', noExHeight)
            this.$store.commit('changeTableHeight', height)
        },
        // 改变宽度
        changeDetail() {
            let width = window.innerWidth
            if (this.timeDeatail) {
                clearTimeout(this.timeDeatail)
            }
            if (width > (1300 * window.devicePixelRatio) && width < (1700 * window.devicePixelRatio)) {
                this.timeDeatail = setTimeout(() => {
                    this.$store.commit('changeDetailWidth', '70%')
                }, 200);
            }
            if (width < (1300 * window.devicePixelRatio) && width > (1000 * window.devicePixelRatio)) {
                this.timeDeatail = setTimeout(() => {
                    this.$store.commit('changeDetailWidth', '80%')
                }, 200);
            }
            if (width < (1000 * window.devicePixelRatio)) {
                this.timeDeatail = setTimeout(() => {
                    this.$store.commit('changeDetailWidth', '90%')
                }, 200);
            }
            if (width > (1700 * window.devicePixelRatio)) {
                this.timeDeatail = setTimeout(() => {
                    this.$store.commit('changeDetailWidth', '60%')
                }, 200);
            }
        },

        async getALltitle() {
            let title = await this.$API.account_basic.loginNews.get()
            if (title.data.platform_title == '') {
                title.data.platform_title = this.$t("basic.menubar.backstageManage")
            }
            if (title.data.entity_name == '') {
                title.data.entity_name = this.$t("basic.newLogin.title")
            }

            if (title.data.entityLogo == '') {     //todo
                title.data.entityLogo = ''
            }
            this.$store.commit('getAllTitle', title.data)
        },
        //侧边弹框
        async showNotify(msg) {
            let _this = this;
            /*
            // 屏蔽设备获取屏蔽设备列表, 通过ID匹配进行拦截弹窗
            let data = null;
            let flag = false;
            data = await this.$API.device.getBlackAlarmDeviceList.get();
            if (data.code == 200 ) { // 请求成功
              if (data.data.count != 0) { // count 为0时表示没有被屏蔽的设备, 不执行下面的代码
                console.log(2);
                for (let i = 0; i < data.data.data.length; i++) {
                  if ( data.data.data[i].id == msg.device_id ) {
                    console.log(3);
                    flag = true;
                    console.log("有相同的设备, 开始拦截");
                    return;
                  }
                } 
              }
            }
            console.log(flag);
            // 经过以上的设备id匹配, 如果匹配到黑名单设备, 则不弹窗报警, 否则继续往下执行
            if(flag) return;
            */
            const h = this.$createElement
            let notify = this.$notify({
                onClose: function () {
                    if (_this.alarmId == msg.alarm_id) {
                        clearTimeout(_this.audioLoop);
                        _this.audioLoop = null
                        if (_this.videoMsg) window.speechSynthesis.cancel()
                        _this.videoMsg = null
                    }
                    _this.msgNotify = ''
                    _this.policeState = false;
                },
                duration: 0,
                dangerouslyUseHTMLString: true,
                customClass: 'notify-msg',
                message: h(
                    'div',
                    {
                        class: 'notify-msg-box',
                    },
                    [
                        h('div', { class: 'notify-msg-top' }, [
                            h('div', { class: 'notify-msg-icon' }, [
                                h('div', { class: 'image', attrs: { id: msg.alarm_id }, on: { click: () => { this.test(msg.alarm_id) } } }),
                            ]),
                            h('div', { class: 'notify-msg-top-right' }, [
                                h('div', { class: 'notify-msg-title' }, this.$t("basic.all.mqtt_deviceAlarm")),
                                h('div', { class: 'notify-msg-context' }, this.$t("basic.public.deviceCoding") + '：' + msg.device_number),
                                h('div', { class: 'notify-msg-context' }, this.$t("basic.public.typeTitle") + '：' + msg.device_type),
                                h('div', { class: 'notify-msg-context' }, this.$t('basic.pushRecords.AliSmsTime') + '：' + msg.device_data.time),
                                h('div', { class: 'notify-msg-context' }, this.$t('basic.digitalPage.middenMap_owenr') + '：' + msg.owner.contactName + ' ' + msg.owner.contactrPhone),
                                h('div', { class: 'notify-msg-context' }, this.$t("basic.deviceList.bind_installSite") + '：' + msg.location),
                            ]),
                        ]),

                        h('div', { class: 'notify-msg-bottom' }, [
                            h(
                                'button',
                                //  样式修改                    方法赋值
                                { class: 'notify-msg-btn', on: { click: () => { this.openAlarm(msg.alarm_data, msg) } } },
                                this.$t("basic.all.mqtt_deviceAlarmDetail"),
                            ),
                            h(
                                'button',
                                { class: 'notify-msg-btn', on: { click: () => { this.openVideo(msg) } } },
                                this.$t("basic.all.mqtt_checkVideo")
                            ),
                            h(
                                'button',
                                { class: 'notify-msg-btn', on: { click: () => { this.openPicture(msg.pointPhoto, msg.local_coord) } } },
                                this.$t("basic.alarmPlatform.planeImg")
                            ),
                            h(
                                'button',
                                { class: 'notify-msg-btn-last', on: { click: () => { this.open3D() } } },
                                this.$t("basic.all.mqtt_model")
                            ),
                        ]),

                    ]
                ),
                offset: 50, // 向下偏移100
            })

            this.notifications[msg.alarm_id + ""] = notify;
            this.mqttText = msg.location;
            this.alarmId = msg.alarm_id
        },

        // 打开平面图
        openPicture(msg, point) {
            if (!msg) {
                messageAll.warningOpen(this.$t("basic.alarmPlatform.message_plangImgLose"))
                return
            }
            this.dialogtitle = this.$t("basic.alarmPlatform.planeImg")
            this.planShow += 1
            this.dialogTabShow = 'plan'
            this.dialogVisible = true
            this.IMGposition = point
            this.AxiosURL = msg
            this.$nextTick(() => {
                this.$refs.planPicture.initMap()
            })

        },

        // 打开视屏
        async openVideo(msg) {
            this.dialogtitle = this.$t("basic.all.mqtt_checkVideo")
            this.alarmData = msg
            this.dialogTabShow = 'video'
            this.dialogVisible = true
        },

        // 报警信息
        openAlarm(msg, val) {
            console.log("msg=", msg)
            console.log("val=", val)
            this.dialogtitle = this.$t("basic.all.mqtt_deviceAlarmDetail")
            this.mapPosition['lat'] = val.lat
            this.mapPosition['lon'] = val.lng
            this.dialogTabShow = 'alarmNews'
            this.dialogVisible = true
            this.alarm_data = msg
            this.allAlarmData = val

            // this.$nextTick(()=>{
            //   this.$refs.alarmDetail.disposeAlarm.cause = '',
            //   this.$refs.alarmDetail.disposeAlarm.remarks = ''
            // })
        },

        // 处理报警
        clearAlarm(devcieId, alarmId) {
            // 关闭弹框
            this.dialogTabShow = "";

            this.notifications[alarmId + ""].close();
            this.dialogVisible = false
            clearTimeout(this.audioLoop)
            this.audioLoop = null
            if (this.videoMsg) window.speechSynthesis.cancel()
            // if(this.alarmId == alarmId){

            // }
        },

        // 3D模型
        open3D() {
            messageAll.warningOpen("此设备暂无3D模型!")
        },

        // 拼接服务器URL
        getURL(url) {
            console.log(url);
            return this.$TOOL.getMediaUrl(url)
        },
        test(val) {
            let el = document.getElementById(val)
            this.policeState = false;
            el.className = 'imageClose'
            window.speechSynthesis.cancel()
            this.videoMsg = null
            clearTimeout(this.audioLoop)
            this.audioLoop = null
        },

        changeTime() {
            let Time = document.getElementsByClassName('has-time')
            if (Time.length > 0) {
                let i = 0
                for (i = 0; i < Time.length; i++) {
                    Time[i].style.display = 'none'
                }
            }
        }
    },
    computed: {
        getAlarmStatusByStore() {
            return this.$store.state.alarmType
        }
    }
}
</script>


<style lang="scss">
@import "@/assets/scss/theme/handle";

#app {
    @include background_color("background_color3");
    overflow: hidden;
    // display: flex;
}
.textPoint{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
// 去除滚动条
// ::-webkit-scrollbar {
//         width: 0px;
//         height: 10px;
//         background-color:rgb(240,242,245);
//       }

// 滚动条  
::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(240, 242, 245);
    border-radius: 8px;
}

// 滚动条滑块样式
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgb(97, 97, 97);
}

/**
修改notify样式
 */
.notify-msg {
    margin: 0;
    padding: 0;
    width: 440px;
    z-index: 1000 !important;

    .el-notification__group {
        margin: 0;
        padding: 0;
    }
}
</style>

<style lang="scss" scoped>

body {
    overflow: hidden;
}

.notify-msg-box {
    margin: 0;
    padding: 0;

    .notify-msg-top {
        display: flex;
        padding: 20px 20px 20px 20px;

        .notify-msg-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .image {
                width: 55px;
                height: 70px;
                cursor: pointer;
                background-image: url('assets/img/alarm_on.gif');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                -moz-background-size: 100% 100%;
            }

            .imageClose {
                width: 55px;
                height: 70px;
                cursor: pointer;
                background-image: url('assets/img/ea94042347aebe95da6fec99d6f8b99.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                -moz-background-size: 100% 100%;
            }
        }

        .notify-msg-top-right {
            padding-left: 30px;

            .notify-msg-title {
                font-weight: 700;
                font-size: 20px;
                color: #f56c6c;
            }

            .notify-msg-context {
                padding-top: 8px;
                color: #666666;
                font-size: 14px;
            }
        }
    }

    .notify-msg-bottom {
        display: flex;
        height: 50px;
        width: 440px;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: #f1f1f1;

        .notify-msg-btn {
            background: #409eff;
            border: none;
            color: #ffffff;
            width: 80px;
            height: 35px;
            border-radius: 5px;
            cursor: pointer;
            margin-right: 20px;
        }

        .notify-msg-btn-last {
            background: #409eff;
            border: none;
            color: #ffffff;
            width: 80px;
            height: 35px;
            border-radius: 5px;
            cursor: pointer;
        }

        .notify-msg-btn:hover,
        .notify-msg-btn-last:hover {
            background: #8cc5ff;
        }
    }

    .notify-bottom {
        width: 440px;
        display: flex;
        flex-direction: row-reverse;

        .el-button {
            margin-bottom: 20px;
            margin-right: 30px;
            color: white;
            font-weight: 700;
            width: 100px;
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 14px;
            background-color: #409eff;
        }
    }

}

.AppDialogBox {
    width: 100%;
    height: 70vh;
}

// .AppDialog{
::v-deep .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>