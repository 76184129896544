import config from "@/config"
import http from "@/utils/request"
export default {
	imageList: {
		url: `${config.API_URL}/deviceImage/`,
		name: "查看设备图片列表",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	addImage:{
		url: `${config.API_URL}/deviceImage/`,
		name: "添加设备图片",
		post: async function(id,data){
			return await http.post(this.url+id+'/', data);
		}
	},
    deleteImage: {
		url: `${config.API_URL}/deviceImage/`,
		name: "删除设备图片",
		delete: async function(id,data){
			return await http.delete(this.url+id+'/', data);
		}
	},
    attrList: {
		url: `${config.API_URL}/deviceAttributeList/`,
		name: "设备属性统计列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    attrLatest: {
		url: `${config.API_URL}/deviceAttributeLatest/`,
		name: "设备属性最新数据",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    uploadAlarm: {
		url: `${config.API_URL}/deviceUploadAlarm/`,
		name: "获取设备上报的报警数据",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
    uploadFault: {
		url: `${config.API_URL}/deviceUploadFault/`,
		name: "获取设备上报的故障数据",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
    uploadHeart: {
		url: `${config.API_URL}/deviceUploadHeartbeat/`,
		name: "获取设备上报的心跳数据",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	upInstruct:{
		url:`${config.API_URL}/command/`,
		name: "获取指令下发记录",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	bindUser:{
		url:`${config.API_URL}/bindDevicePersonnel/`,
		name: "绑定权利",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	updateEmpower:{
		url:`${config.API_URL}/updateEmpower/`,
		name: "修改用户执行权限",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	somkeDetector:{
		url:`${config.API_URL}/device/`,
		name: "433烟感",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	somkeChiledDevice:{
		url:`${config.API_URL}/query433IbmChildDevice/`,
		name: "433烟感主机下面的烟感设备",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	smokerChilerAddr:{
		url:`${config.API_URL}/upload433IbmChildDeviceAddress/`,
		name: "修改用户执行权限",
		post: async function(id,data){
			return await http.post(this.url+id+'/', data);
		}
	},
	downholeGasDetectors: {
		url: `${config.API_URL}/downholeGasDetectors/`,
		name: '查询"地下空间及井下燃气探测器"其它数据',
		get: async function(id){
			return await http.get(this.url + id)
		}
	},
	setGatherUpload: {
		url: "https://iot.tranthing.com/undergroundGas/order/setGatherUpload",
		name: "地下空间及井下燃气探测器下发功能",
		post: async function (data) {
			return await http.post(this.url, data);
		}
    },
    
    queryIA4GRC: {
        url: `${config.API_URL}/industryAas4gRelevanceCamera/` ,
        name: "查询4g工业燃气关联摄像头",
        get: async function (id) {
            return await http.get(this.url + id);
        }
    },
    relevanceCamera: {
        url: `${config.API_URL}/industryAas4gRelevanceCamera/add/`,
        name: "4g工业燃气关联摄像头",
        post: async function (id, data) {
            return await http.post( this.url + id, data )
        }
    },
    deleterelevanceCamera: {
        url: `${config.API_URL}/industryAas4gRelevanceCamera/del/`,
        name: "取消关联摄像头",
        post: async function (id, data) {
            return await http.post(this.url + id, data)
        }
    },

    setWarnValue: {
        url: `https://iot.tranthing.com/undergroundGas/order/setWarnValue`,
        name: "下发高报低报阈值指令(生产)",
        post: async function (data) {
            return await http.post(this.url, data);

        }
    },
    getTrendDataAPI: {
        url: `${config.API_URL}/concentrationTrend/`,
        name: "获取4g工业燃气浓度趋势数据",
        get: async function (id, data) {
            return await http.get(this.url + id, data);
        }
    },
    
    setSensitivity: {
        url: `http://47.106.184.185:8565/order/setVibrateSensitivity`,
        name: "下发探测器的震动传感器灵敏度",
        post: async function (data) {
            return await http.post(this.url, data)
        }
    },

    querySleepRadar: {
        url: `${config.API_URL}/R60ABD1/query/`,
        name: "查询睡眠雷达数据信息",
        get: async function (id) {
            return await http.get(this.url + id);
        }
    },
    querySleepSynthesisState: {
        url: `${config.API_URL}/R60ABD1/query/sleepQualityAnalysis/`,
        name: "根据设备id查询睡眠综合状态数据",
        get: async function (id, data) {
            return await http.get(this.url + id, data);
        }
    },
    sleepQualityAnalysis: {
        url: `${config.API_URL}/R60ABD1/query/sleepSynthesisState/`,
        name: "根据设备id查询睡眠质量分析数据",
        get: async function (id, data) {
            return await http.get(this.url + id, data);
        }
    },
    humanExistence: {
        url: `https://iot.tranthing.com/sleep_radar/issued/humanExistenceSwitch/`,
        // url: `http://192.168.3.10:8006/issued/humanExistenceSwitch/`,
        name: "开关人体存在",
        post: async function (deviceNum, data) {
            return await http.post(this.url + deviceNum + "/", data)
        }
    },
    heartRateMonitoring: {
        url: `https://iot.tranthing.com/sleep_radar/issued/heartRateMonitoringSwitch/`,
        // url: `http://192.168.3.10:8006/issued/heartRateMonitoringSwitch/`,
        name: "开关心率监测功能",
        post: async function (deviceNum, data) {
            return await http.post(this.url + deviceNum + "/", data)
        }
    },
    respiratoryMonitoring: {
        url: `https://iot.tranthing.com/sleep_radar/issued/respiratoryMonitoringSwitch/`,
        // url: `http://192.168.3.10:8006/issued/respiratoryMonitoringSwitch/`,
        name: "开关呼吸监测功能",
        post: async function (deviceNum, data) {
            return await http.post(this.url + deviceNum + "/", data)
        }
    },
    sleepMonitoring: {
        url: `https://iot.tranthing.com/sleep_radar/issued/sleepMonitoringSwitch/`,
        // url: `http://192.168.3.10:8006/issued/sleepMonitoringSwitch/`,
        name: "开关睡眠监测功能",
        post: async function (deviceNum, data) {
            return await http.post(this.url + deviceNum + "/", data)
        }
    },
    reportingMode: {
        url: `https://iot.tranthing.com/sleep_radar/issued/reportingMode/`,
        // url: `http://192.168.3.10:8006/issued/reportingMode/`,
        name: "上报模式选择",
        post: async function (deviceNum, data) {
            return await http.post(this.url + deviceNum + "/", data)
        }
    },

    strugglingState: {
        url: `https://iot.tranthing.com/sleep_radar/issued/strugglingStateSwitch/`,
        // url: `http://192.168.3.10:8006/issued/strugglingStateSwitch/`,
        name: "异常挣扎状态功能",
        post: async function (deviceNum, data) {
            return await http.post(this.url + deviceNum + "/", data)
        }
    },

    regionalScope: {
        url: `${config.API_URL}/R60ABD1/query/beyondRegionalScope/`,
        name: "位置越界",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },

    presenceState: {
        url: `${config.API_URL}/R60ABD1/query/humanBodyPresenceState/`,
        name: "人体存在",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },

    heartRate: {
        url: `${config.API_URL}/R60ABD1/query/heartRate/`,
        name: "心率数值",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    humanMoveState: {
        url: `${config.API_URL}/R60ABD1/query/humanBodyMoveState/`,
        name: "人体运动",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    breatheState: {
        url: `${config.API_URL}/R60ABD1/query/breatheState/`,
        name: "呼吸状态",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    breatheValue: {
        url: `${config.API_URL}/R60ABD1/query/breatheValue/`,
        name: "呼吸数值",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    inBedState: {
        url: `${config.API_URL}/R60ABD1/query/inBedState/`,
        name: "入床/离床状态",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    sleepState: {
        url: `${config.API_URL}/R60ABD1/query/sleepState/`,
        name: "睡眠状态",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    lucidDuration: {
        url: `${config.API_URL}/R60ABD1/query/lucidDuration/`,
        name: "清醒时长",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    catnapDuration: {
        url: `${config.API_URL}/R60ABD1/query/catnapDuration/`,
        name: "浅睡时长",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    deepSleepDuration: {
        url: `${config.API_URL}/R60ABD1/query/deepSleepDuration/`,
        name: "深睡时长",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    sleepDisorders: {
        url: `${config.API_URL}/R60ABD1/query/sleepDisorders/`,
        name: "睡眠异常",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    abnormalFlounderState: {
        url: `${config.API_URL}/R60ABD1/query/abnormalFlounderState/`,
        name: "异常挣扎状态",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },
    sleepQualityRating: {
        url: `${config.API_URL}/R60ABD1/query/sleepQualityRating/`,
        name: "睡眠质量评级",
        get: async function (id, data) {
            return await http.get(this.url + id, data)
        }
    },

    fallRadarDetail: {
        url: `${config.API_URL}/fd101/`,
        name: "查询跌倒雷达详情",
        get: async function (id) {
            return await http.get(this.url + id);
        }
    },
    
    fallSetSite: {
        url: `${config.FD1014G_URL}/setSite`,
        name: "场景设置",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },
    fallSetHeight : {
        url: `${config.FD1014G_URL}/setHeight`,
        name: "安装高度",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },
    fallObjectExist: {
        url: `${config.FD1014G_URL}/openObjectExist`,
        // url: "https://apifoxmock.com/m1/4967092-0-default/openObjectExist?state=0",
        name: "开关人体存在功能",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },
    fallMonitor: {
        url: `${config.FD1014G_URL}/openFallMonitor`,
        name: "开关跌倒监测功能",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },
    fallStayTime: {
        url: `${config.FD1014G_URL}/setResidenceTime`,
        name: "驻留时长设置",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },
    fallOpenStop : {
        url: `${config.FD1014G_URL}/openStop`,
        name: "驻留开关设置",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },
    fallTime: {
        url: `${config.FD1014G_URL}/fallTime`,
        name: "跌倒时长设置",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },
    fallSensitivity: {
        url: `${config.FD1014G_URL}/setFallingSensitivity`,
        // url: `https://apifoxmock.com/m1/4967092-0-default/setFallingSensitivity?state=1`,
        name: "跌倒灵敏度设置",
        post: async function (data) {
            return await http.post(this.url, data, "application/json");
        }
    },
}